import React, { useEffect, useState } from 'react';
import { getCreditsHistory } from '../api'; 
import Header from './Header';

const CreditHistory = () => {
    const [creditHistory, setCreditHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchCreditHistory = async () => {
            try {
                const response = await getCreditsHistory();
                setCreditHistory(response.data || []);
            } catch (error) {
                console.error('Error fetching credit history:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCreditHistory();
    }, []);

    if (loading) {
        return <p className="text-gray-500">Loading credit history...</p>;
    }

    return (
        <div>
            <Header/>
        <div className="container mx-auto mt-24">
            <h1 className="text-3xl font-bold mb-4">Credit History</h1>
            <table className="min-w-full bg-gray-100 border border-gray-300">
                <thead>
                    <tr>
                        <th className="border border-gray-300 px-4 py-2">ID</th>
                        <th className="border border-gray-300 px-4 py-2">Description</th>
                        <th className="border border-gray-300 px-4 py-2">Type</th>
                        <th className="border border-gray-300 px-4 py-2">Amount</th>
                        <th className="border border-gray-300 px-4 py-2">Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                {Array.isArray(creditHistory) && creditHistory.length > 0 ? (
    creditHistory.map((item) => (
        <tr key={item.id}>
            <td className="border border-gray-300 px-4 py-2">{item.id}</td>
            <td className="border border-gray-300 px-4 py-2">{item.description}</td>
            <td className="border border-gray-300 px-4 py-2">{item.type}</td>
            <td className="border border-gray-300 px-4 py-2">{item.amount}</td>
            <td className="border border-gray-300 px-4 py-2">{new Date(item.timestamp).toLocaleString()}</td>
        </tr>
    ))
) : (
    <tr>
        <td colSpan="5" className="text-center border border-gray-300 px-4 py-2">No credit history available.</td>
    </tr>
)}

                </tbody>
            </table>
        </div>
        </div>
    );
};

export default CreditHistory;
