import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa"; 

const Header = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <header className="bg-gray-800 text-white fixed top-0 left-0 w-full z-50 shadow-md">
            <div className="container mx-auto flex justify-between items-center px-6 py-4">
                <h2 className="text-2xl font-bold">Internet Feed</h2>

                <nav className="hidden md:flex space-x-6">
                    <ul className="flex space-x-6">
                        <li>
                            <a href="/" className="hover:text-gray-300">Home</a>
                        </li>
                        <li>
                            <a href="/" className="hover:text-gray-300">Features</a>
                        </li>
                        <li>
                            <a href="/" className="hover:text-gray-300">Pricing</a>
                        </li>
                        <li>
                            <a href='/login' className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-4 rounded">Login</a> 
                        </li>
                        <li>
                            <a href="/signUp" className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded">Register Now</a>
                        </li>
                    </ul>
                </nav>

                <button className="text-white md:hidden z-50 relative" onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
                </button>
            </div>
            
            {isMobileMenuOpen && (
                <nav className="md:hidden bg-gray-700 text-white fixed top-0 right-0 h-50 w-2/5 p-4 flex flex-col justify-center items-center shadow-lg z-40">
                    <ul className="space-y-6 text-center">
                        <li>
                            <a href="/" className="hover:text-gray-300 text-lg">Home</a>
                        </li>
                        <li>
                            <a href="/" className="hover:text-gray-300 text-lg">Features</a>
                        </li>
                        <li>
                            <a href="/" className="hover:text-gray-300 text-lg">Pricing</a>
                        </li>
                        <li>
                            <a href='/login' className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-6 rounded">Login</a>
                        </li>
                        <li>
                            <a href="/signUp" className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-6 rounded">Register Now</a>
                        </li>
                    </ul>
                </nav>
            )}
        </header>
    );
};

export default Header;
