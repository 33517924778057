import React, { useState, useEffect } from 'react';
import { getPeopleAlerts } from '../api';
import Header from './Header';

const PersonUpdatesPage = () => {
    const [name, setName] = useState('');
    const [updates, setUpdates] = useState([]);
    const [loading, setLoading] = useState(false);

    // Function to fetch data from API
    const fetchPersonUpdates = async () => {
        setLoading(true);
        try {
            const response = await getPeopleAlerts(); // Pass `personName` to the API call if needed
            const data = await response.json();
            setUpdates(data);
            console.log("Full API response:", response)
        } catch (error) {
            console.error("Error fetching person updates:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch data when the page loads if needed
    useEffect(() => {
        if (name) {
            fetchPersonUpdates(name);
        }
    }, []);

    // Handle search
    const handleSearch = () => {
        if (name.trim() !== '') {
            fetchPersonUpdates(name);
        }
    };
    
    return (
        <div>
            <Header />
            <div className="min-h-screen bg-gray-100 pt-28 p-8">
                <h1 className="text-3xl font-bold mb-6">Person Updates</h1>

                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Person Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="p-2 border border-gray-300 rounded mr-2"
                    />
                    <button
                        onClick={handleSearch}
                        className="bg-black text-white px-4 py-2 rounded"
                    >
                        {loading ? "Searching..." : "Search"}
                    </button>
                </div>

                {/* Table for displaying updates */}
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white shadow-md rounded-lg">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b font-bold">Title</th>
                                <th className="py-2 px-4 border-b font-bold">Content</th>
                                <th className="py-2 px-4 border-b font-bold">Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {updates.length > 0 ? (
                                updates.map((update, index) => (
                                    <tr key={index} className="hover:bg-gray-50">
                                        <td className="py-2 px-4 border-b">{update.title}</td>
                                        <td className="py-2 px-4 border-b">{update.content}</td>
                                        <td className="py-2 px-4 border-b">{update.type}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="py-4 text-center text-gray-500">
                                        No updates found for "{name}"
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PersonUpdatesPage;
