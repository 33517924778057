import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';
import { getSubscriptions } from '../api'; 

const HomePage = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null); 

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const data = await getSubscriptions();
                setSubscriptions(data); 
            } catch (err) {
                setError('Failed to load subscriptions.');
            } finally {
                setLoading(false); 
            }
        };

        fetchSubscriptions();
    }, []);

    return (
        <div className="min-h-screen bg-gray-100">
            <Header />
            <main className="pt-28 p-8 max-w-3xl mx-auto">
                <h2 className="text-2xl font-bold mb-3 text-gray-800">Your Subscriptions</h2>

                {loading ? (
                    <p className="text-gray-700 text-md">Loading subscriptions...</p>
                ) : error ? (
                    <p className="text-red-600 text-md">{error}</p>
                ) : subscriptions.length === 0 ? (
                    <p className="text-gray-700 text-md">
                        You don't have any subscriptions yet.{' '}
                        <Link to="/create-subscription" className="text-indigo-600 hover:underline font-medium">
                            Create Subscription?
                        </Link>
                    </p>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 ">
                        {subscriptions.map((subscription, index) => (
                            <div key={index} className="p-4 bg-white shadow-md space-y-2 rounded-lg">
                                <h3 className="font-bold text-xl capitalize">
                                    {subscription.entity_type} Subscription ({subscription.status})
                                </h3>
                                <p><strong>Channels:</strong> {subscription.preferred_channels?.join(', ') || 'N/A'}</p>
                                <p><strong>Cadence:</strong> {subscription.cadence || 'N/A'}</p>
         
                                {subscription.entity_type === 'job' && subscription.roles && (
                                    <div>
                                        <p><strong>Roles:</strong></p>
                                        <ul className="list-disc pl-5">
                                            {subscription.roles.map((role, i) => (
                                                <li key={i}>{role}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                                
                                {subscription.entity_type === 'event' && subscription.event_details && (
                                    <div>
                                        <p><strong>Event Details:</strong> {subscription.event_details.industries || subscription.event_details.location}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </main>
        </div>
    );
};

export default HomePage;
