import React, { useState } from 'react';
import { createSubscription, findPerson } from '../api'; // Assuming Subscriptions function is exported from api.js
import Header from './Header';

const countries = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", 
  "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", 
  "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", 
  "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana", 
  "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", 
  "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic", 
  "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", 
  "Croatia", "Cuba", "Cyprus", "Czech Republic", "Democratic Republic of the Congo", 
  "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", 
  "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", 
  "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", 
  "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", 
  "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", 
  "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", 
  "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan", 
  "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", 
  "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", 
  "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", 
  "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", 
  "Marshall Islands", "Mauritania", "Mauritius", "Mexico", "Micronesia", 
  "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", 
  "Myanmar", "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", 
  "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", 
  "Oman", "Pakistan", "Palau", "Palestine", "Panama", "Papua New Guinea", 
  "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", 
  "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", 
  "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", 
  "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", 
  "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", 
  "Solomon Islands", "Somalia", "South Africa", "South Africa", "Spain", 
  "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", 
  "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", 
  "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", 
  "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", 
  "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", 
  "Venezuela", "Vietnam", "Yemen", "Zambia", "Zimbabwe"
];
const roles = [
    "Software Engineer","Frontend Developer","Backend Developer","Full Stack Developer","Data Scientist","Machine Learning Engineer","Data Engineer",
    "DevOps Engineer","Cloud Engineer",
    "Mobile App Developer","iOS Developer",
    "Android Developer", "Security Engineer", "Network Engineer","UI/UX Designer","Product Manager","Technical Project Manager","IT Support Specialist","System Administrator","Quality Assurance Engineer","Test Automation Engineer","Embedded Systems Engineer","Blockchain Developer","Game Developer","VR/AR Developer","AI Research Scientist","Robotics Engineer","Database Administrator","Solutions Architect","Site Reliability Engineer","Technical Writer","Data Analyst","Business Intelligence Analyst","Data Visualization Specialist","Cybersecurity Analyst","Computer Vision Engineer","Natural Language Processing Engineer",
    "IoT Engineer","Digital Marketing Specialist","SEO Specialist","IT Consultant","Software Architect",
    "Bioinformatics Specialist","Technical Recruiter",
    "Technical Support Engineer","Firmware Engineer","ERP Consultant", "Chief Technology Officer", "Chief Information Officer",
    "Scrum Master", "Agile Coach","IT Auditor","Penetration Tester","Computer Scientist","Software Development Manager","Hardware Engineer", "Tech Support Analyst"
  ];
  
  const techSkills = [
    "Data Analysis", "Machine Learning", "Deep Learning", "TensorFlow", "PyTorch", "Pandas", "NumPy", "SQL for Data Analysis", 
    "Agile Methodologies", "UX/UI Design", "Mobile Development (iOS/Android)", "Web Development", "Software Testing and Quality Assurance", 
    "JavaScript", "Python", "React", "Node.js", "Java", "C#", "Ruby", "TypeScript", "SQL", "NoSQL", "AWS", "Azure", 
    "Google Cloud", "Docker", "Kubernetes", "Angular", "Vue.js", "HTML", "CSS", "GraphQL", "Rust", "Go", "Swift", 
    "Objective-C", "Django", "Flask", "Spring", "Express", "Keras", "Apache", "Nginx", "Jenkins", "Git", "Bitbucket", 
    "Terraform", "Ansible", "Scrum", "Cybersecurity", "Penetration Testing", "Ethical Hacking", "Microservices", 
    "Blockchain", "Internet of Things (IoT)", "Data Visualization", "MATLAB", "Tableau", "Power BI", "Redis", 
    "Elasticsearch", "Sass", "LESS", "Figma", "Adobe XD", "Ruby on Rails", "Unity", "Game Development", 
    "Voice User Interface (VUI)", "Natural Language Processing (NLP)", "Computer Vision", "Chatbot Development", 
    "API Development", "Web Scraping", "SEO", "Digital Marketing", "Content Management Systems (CMS)", "WordPress", 
    "Shopify", "Magento","Critical Thinking", "Problem Solving", "Project Management", "Team Collaboration", "Communication", 
    "Time Management","Next js", "Adaptability", "Creativity", "Attention to Detail", "Research Skills", "User Research", 
    "Customer Service", "Public Speaking", "Technical Writing", "Data Interpretation", "Business Analysis", 
    "Strategic Planning", "Negotiation", "Networking", "Presentation Skills", "Conflict Resolution", "Mentoring", 
    "Coaching", "Decision Making", "Emotional Intelligence", "Change Management"
  ];
  
  const CreateSubscriptionPage = ({ onNewSubscription }) => {
    const [entity_type, setEntityType] = useState('');
    const [channels, setChannels] = useState([]);
    const [cadence, setCadence] = useState('');
    const [jobDetails, setJobDetails] = useState({
      roles: [],
      minimum_compensation: '',
      skills: [],
      supports_relocation: false,
      is_remote: false,
      job_types: '',
      locations: [],
    });
    const [personDetails, setPersonDetails] = useState({
      search_type: '',
      person_search: ''
    });
    const [eventDetails, setEventDetails] = useState({
      keywords:[],
      type: [],
      locations: [],
      industries: [],
      size: '',
      maximum_price: '',
      is_online: false,
  });
    const [personSearch, setPersonSearch] = useState(""); 
    const [skillSearch, setSkillSearch] = useState(""); 
    const [suggestions, setSuggestions] = useState([]);
    const [isPersonFocused, setIsPersonFocused] = useState(false);
    const [isSkillFocused, setIsSkillFocused] = useState(false); 

    const handleSearchChange = (e) => {
        const value = e.target.value;
      
        if (isPersonFocused) {
          setPersonSearch(value);
         if (value && e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
            searchPerson(value); }
        } else if (isSkillFocused) {
          setSkillSearch(value);
         if (value) {
            const filteredSuggestions = techSkills.filter((skill) =>
              skill.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
          } else {
            setSuggestions([]);
          }
        }
      };

      
      const searchPerson = async (source) => {
        try {
          const response = await findPerson(personSearch ,source);
          console.log(response);
          setPersonDetails({
            ...personDetails,
            results: response,
        });
        } catch (error) {
          console.error("Error searching for person:", error);
        }
      };
      const handleKeyDown = async (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
          e.preventDefault();
       await searchPerson(personDetails.source);
        }
      };
  
    const handleSuggestionClick = (skill) => {
        if (!jobDetails.skills.includes(skill)) {
          setJobDetails((prev) => ({
            ...prev,
            skills: [...prev.skills, skill],
          }));
        }
        setPersonSearch(''); 
        setSuggestions([]);
      };

      const updateArrayField = (field, value) => {
        setEventDetails((prevDetails) => {
          const updated = {
            ...prevDetails,
            [field]: [...prevDetails[field], value],
          };
          console.log('Updated Event Details:', updated); 
             return updated;
        });
      };
      
      const removeArrayField = (field, value) => {
        setEventDetails((prevDetails) => {
          const updated = {
            ...prevDetails,
            [field]: prevDetails[field].filter((item) => item !== value),
          };
          console.log('Updated After Removal:', updated); 
          return updated;
        });
      };
      
      
      const handleSubmit = async (e) => {
        e.preventDefault();
      
        const normalizedJobDetails = {
          roles: jobDetails.roles.map((role) => role.toLowerCase()),
          minimum_compensation: jobDetails.minimum_compensation.toLowerCase(),
          skills: jobDetails.skills.map((skill) => skill.toLowerCase()),
          job_types: jobDetails.job_types.toLowerCase(),
          locations: jobDetails.locations,
        };
      
        const normalizedPersonDetails = {
          search_type: personDetails.search_type.toLowerCase(),
          person_search: personSearch.toLowerCase(),
        };


        const normalizedEventDetails = {
          keywords: eventDetails.keywords, 
          type: eventDetails.type,         
          locations: eventDetails.locations, 
          industries: eventDetails.industries,
          size: eventDetails.size ? eventDetails.size.toLowerCase() : '',  
          maximum_price: eventDetails.maximum_price || '',
          is_online: eventDetails.is_online || false,
        };
        
        const subscriptionData = {
          entity_type: entity_type.toLowerCase(),
          channels: channels.map((channel) => channel.toLowerCase()),
          cadence: cadence.toLowerCase(),
        };

        if (entity_type === 'job' && jobDetails) {
          subscriptionData.roles = normalizedJobDetails.roles;
          subscriptionData.minimum_compensation = normalizedJobDetails.minimum_compensation;
          subscriptionData.skills = normalizedJobDetails.skills;
          subscriptionData.job_types = normalizedJobDetails.job_types;
          subscriptionData.locations = normalizedJobDetails.locations;
        }
        
        if (entity_type === 'person' && personDetails) {
          subscriptionData.search_type = normalizedPersonDetails.search_type;
          subscriptionData.person_search = normalizedPersonDetails.person_search;
        }
        
        if (entity_type === 'event' && eventDetails) {
          subscriptionData.keywords = normalizedEventDetails.keywords;
          subscriptionData.type = normalizedEventDetails.type;
          subscriptionData.locations = normalizedEventDetails.locations;
          subscriptionData.industries = normalizedEventDetails.industries;
          subscriptionData.size = normalizedEventDetails.size;
          subscriptionData.maximum_price = normalizedEventDetails.maximum_price;
        }
        
        
        try {
          await createSubscription(subscriptionData);
          onNewSubscription(subscriptionData);
          console.log('Subscription created successfully');
        } catch (error) {
          console.error('Error creating subscription:', error);
         }
      };
      
  
    return (
      <div>
        <Header/>
        <div className="min-h-screen bg-gray-100 flex items-center justify-center mt-10 py-10 px-4 sm:px-6 lg:px-8">
          <div className="max-w-2xl w-full space-y-4 bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold text-center text-gray-900">Create Subscription</h2>
      
            <form onSubmit={handleSubmit} className="space-y-3">
              <div>
                <label className="block text-gray-700">Entity Type:</label>
                <select
                  value={entity_type}
                  onChange={(e) => setEntityType(e.target.value)}
                  className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="">Select</option>
                  <option value="job">Job</option>
                  <option value="person">Person</option>
                  <option value="event">Events</option>
                </select>
              </div>
      
              <div>
                <label className="block text-gray-700">Channels</label>
                <select
                  multiple
                  value={channels}
                  onChange={(e) => setChannels([...e.target.selectedOptions].map(option => option.value))}
                  className="mt-1 block h-24 w-full  border  border-gray-300 bg-white rounded-sm shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option  className= "w-full p-1"value="whatsapp">WhatsApp</option>
                  <option className= "w-full p-1"value="Telegram">Telegram</option>
                  <option className= "w-full p-1" value="Email">Email</option>
                </select>
                <p className="text-sm text-gray-500">(Hold Ctrl or Command to select multiple)</p>
              </div>
      
              {/* Cadence Selection */}
              <div>
                <label className="block text-gray-700">Cadence:</label>
                <select
                  value={cadence}
                  onChange={(e) => setCadence(e.target.value)}
                  className="mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-sm shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                >
                  <option value="Daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="Bi-Weekly">Bi-Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>
      
              {/* Job Details */}
              {entity_type === 'job' && (
                <>
                  <div>
                    <label className="block text-gray-700">Roles (Hold Ctrl or Command to select multiple):</label>
                    <select
                      multiple
                      value={jobDetails.roles}
                      onChange={(e) => setJobDetails({ ...jobDetails, roles: [...e.target.selectedOptions].map(option => option.value) })}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      {roles.map((role) => (
                        <option key={role} value={role}>{role}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block text-gray-700">Compensation:</label>
                    <input
                      type="text"
                      placeholder='e.g $100'
                      value={jobDetails.minimum_compensation}
                      onChange={(e) => setJobDetails({ ...jobDetails, minimum_compensation: e.target.value })}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
      
                  <div>
  <label className="block text-gray-700">Selected Skills:</label>
  <div className="flex flex-wrap gap-2 mt-1 mb-2">
    {jobDetails.skills.map((skill, index) => (
      <span key={index} className="bg-indigo-100 text-indigo-800 text-sm px-2 py-1 rounded">
        {skill}
      </span>
    ))}
  </div>
</div>

<div>
  <label className="block text-gray-700">Skills:</label>
  <input
    type="text"
    value={skillSearch}
    onFocus={() => {
        setIsSkillFocused(true);
        setIsPersonFocused(false);
      }}
      onBlur={() => setIsSkillFocused(false)}
      onChange={handleSearchChange}
    placeholder="Type to search..."
    className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
  />
  {  suggestions.length > 0 && (
    <ul className="mt-2 max-h-32 overflow-auto border border-gray-300 rounded-md shadow-sm">
      {suggestions.map((skill) => (
        <li
          key={skill}
          onClick={() => handleSuggestionClick(skill)}
          className="cursor-pointer py-1 px-3 hover:bg-gray-200"
        >
          {skill}
        </li>
      ))}
    </ul>
  )}
</div>
                  {/* Relocation and Remote Options */}
                  <div >
                  <input
                      type="checkbox"
                      checked={jobDetails.supports_relocation}
                      onChange={(e) => setJobDetails({ ...jobDetails, supports_relocation: e.target.checked })}
                      className="mt-2 mx-2"
                    />
                    <label className=" text-gray-700">Supports Relocation</label>
                  </div>
      
                  <div >
                  <input
                      type="checkbox"
                      checked={jobDetails.is_remote}
                      onChange={(e) => setJobDetails({ ...jobDetails, is_remote: e.target.checked })}
                      className="mt-2 mx-2"
                    />
                    <label className="text-gray-700">Remote Position</label>
                  </div>
                  <div>
          <label className="block text-gray-700">Job Type:</label>
          <select
            value={jobDetails.job_types}
            onChange={(e) => setJobDetails({...jobDetails,job_types: e.target.value})}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          >
            <option value="">Select</option>
            <option value="Part-time">Part-time</option>
            <option value="Internship">Internship</option>
            <option value="Contract">Contract</option>
            <option value="Temporary">Temporary</option>
            <option value="Full-time">Full-time</option>
          </select>
        </div>
                  <div>
                    <label className="block text-gray-700">Location:</label>
                    <select
                      value={jobDetails.locations}
                      onChange={(e) => setJobDetails({ ...jobDetails, locations: e.target.value })}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <option value="">Select Country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}

          {/* Person Details */}
         
{entity_type === 'person' && (
    <>
        <div>
            <label className="block text-gray-700">Search Type:</label>
            <select
                value={personDetails.source}
                onChange={(e) => setPersonDetails({ ...personDetails, source: e.target.value })}
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            >
                <option value="">Select</option>
                <option value="wiki">Wiki Search</option>
                <option value="google">Google Search</option>
            </select>
        </div>

        <div className="relative">
            <label className="block text-gray-700">Search for a Person:</label>
            <input
                type="text"
                value={personSearch}
                onFocus={() => {
                    setIsPersonFocused(true);
                    setIsSkillFocused(false);
                }}
                onBlur={() => setIsPersonFocused(false)}
                onChange={handleSearchChange} // Updates the search input value
                onKeyDown={handleKeyDown} // Trigger search on Ctrl+Enter
                className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
            {' Press Ctrl + Enter (or Cmd + Enter on Mac) to search.'}
        </div>
        {/* Render search results */}
        {personDetails.results && personDetails.results.length > 0 && (
            <div className="mt-4">
                <h3 className="font-bold">Search Results:</h3>
                <ul>
                    {personDetails.results.map((person, index) => (
                        <li key={index} className="py-2">
                            <div className="font-semibold">{person.name}</div>
                            <div className="text-sm">{person.brief_bio}</div>
                         </li>
                    ))}
                </ul>
            </div>
        )}

        {/* If no results, show a message */}
        {personDetails.results && personDetails.results.length === 0 && (
            <div className="mt-4 text-gray-500">No results found for "{personSearch}"</div>
        )}
    </>
)}

{entity_type === 'event' && (
  <>
 <div className="mb-4">
    <h3 className="text-lg font-semibold mb-2">Keywords</h3>
    <div className="flex flex-wrap gap-2">
    {eventDetails.keywords.map((keyword, index) => {
          console.log('Rendering keyword:', keyword);
          return (
            <span
              key={index}
              className="inline-flex items-center bg-gray-200 px-2 py-1 rounded-full"
            >
              {keyword}
              <button
                onClick={() => {
                  console.log(`Removing keyword: ${keyword}`); // Log removal
                  removeArrayField('keywords', keyword);
                }}
                className="ml-2 bg-white rounded-lg px-1 text-red-500 hover:bg-red-300 transition"
              >
                ×
              </button>
            </span>
          );
        })}
      </div>
      <input
        type="text"
        placeholder="Add a keyword and press Enter"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && e.target.value.trim()) {
            const newKeyword = e.target.value.trim();
            updateArrayField('keywords', newKeyword);
            e.target.value = '';
          }
        }}
        className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
      />
    </div>




    {/* Type Section */}
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Type</h3>
      <div className="flex flex-wrap gap-2">
        {eventDetails.type.map((type, index) => (
          <span key={index} className="inline-flex items-center bg-gray-200 px-2 py-1 rounded-full">
            {type}
            <button
              onClick={() => removeArrayField('type', type)}
              className="ml-2 bg-white rounded-lg px-1 text-red-500 hover:bg-red-300 transition"
            >
              ×
            </button>
          </span>
        ))}
      </div>
      <select
        multiple
        value={eventDetails.type}
        onChange={(e) => {
          const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
          selectedOptions.forEach(option => updateArrayField('type', option)); 
        }}
        className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
      >
        <option value="Party">Party</option>
        <option value="Conference">Conference</option>
        <option value="Meetup">Meetup</option>
        <option value="Webinar">Webinar</option>
      </select>
    </div>

    {/* Locations Section */}
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Locations</h3>
      <div className="flex flex-wrap gap-2">
        {eventDetails.locations.map((location, index) => (
          <span key={index} className="inline-flex items-center bg-gray-200 px-2 py-1 rounded-full">
            {location}
            <button
              onClick={() => removeArrayField('locations', location)}
              className="ml-2 bg-white rounded-lg px-1 text-red-500 hover:bg-red-300 transition"
            >
              ×
            </button>
          </span>
        ))}
      </div>
      <select
        multiple
        value={eventDetails.locations}
        onChange={(e) => {
          const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
          selectedOptions.forEach(option => updateArrayField('locations', option)); 
        }}
        className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
      >
        {countries.map((country, index) => (
          <option key={index} value={country}>
            {country}
          </option>
        ))}
      </select>
    </div>

    {/* Industries Section */}
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">Industries</h3>
      <div className="flex flex-wrap gap-2">
        {eventDetails.industries.map((industry, index) => (
          <span key={index} className="inline-flex items-center bg-gray-200 px-2 py-1 rounded-full">
            {industry}
            <button
              onClick={() => removeArrayField('industries', industry)}
              className="ml-2 bg-white rounded-lg px-1 text-red-500 hover:bg-red-300 transition"
            >
              ×
            </button>
          </span>
        ))}
      </div>
      <select
        multiple
        value={eventDetails.industries}
        onChange={(e) => {
          const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
          selectedOptions.forEach(option => updateArrayField('industries', option)); 
        }}
        className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
      >
        <option value="">Select Industry</option>
        <option value="Technology">Technology</option>
        <option value="Finance">Finance</option>
        <option value="Health">Health</option>
      </select>
    </div>
  

    <label className="block">
      <span className="text-gray-700">Event Size:</span>
      <select
        value={eventDetails.size}
        onChange={(e) => setEventDetails({ ...eventDetails, size: e.target.value })}
        className="mt-2 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
      >
        <option value="">Select Size</option>
        <option value="Small">Small</option>
        <option value="Medium">Medium</option>
        <option value="Large">Large</option>
      </select>
    </label>


              <label className="block">
                <span className="text-gray-700">Maximum Price:</span>
                <input
                  type="number"
                  name="maxPrice"
                  placeholder='enter maximum price'
                  value={eventDetails.maximum_price}
                  onChange={(e) =>setEventDetails({...eventDetails, maximum_price: e.target.value})}
                  className="mt-1 p-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </label>

              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="isOnline"
                  checked={eventDetails.is_online}
                  onChange={(e) =>setEventDetails({...eventDetails, is_online: e.target.checked})}
                  className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
                <span className="ml-2 p-1 text-gray-700">Is Online</span>
              </label>
              </>
              )}

          <button
            type="submit"
            className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Create Subscription
          </button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default CreateSubscriptionPage;
