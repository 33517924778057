import React, { useEffect, useState } from 'react';
import { getJobListings } from '../api'; // Ensure this is correctly implemented
import Header from './Header';

const JobsPage = () => {
  const [jobs, setJobs] = useState([]); // Initialize as an empty array
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(false); // Add loading state for better UX

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true)
      try {
        const response = await getJobListings();
        console.log("Full API response:", response)
        
        if (response.jobs) {
          const { jobs, current_page, has_next } = response;
          setJobs(jobs); // Directly set the jobs array
          setCurrentPage(current_page);
          setHasNext(has_next);
        } else {
          console.error("Unexpected API response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      }
      finally{
        setLoading(false)
      }
    };
  
    fetchJobs();
  }, [currentPage]);
  

  const handleNextPage = () => {
    if (hasNext) setCurrentPage(prevPage => prevPage + 1);
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto pt-28 p-3">
        <h1 className="text-3xl font-bold mb-6">Job Listings</h1>

        {/* Search Filters */}
        <div className="flex flex-wrap gap-4 mb-6">
          <input type="text" placeholder="Job Title" className="w-48 p-2 border border-gray-300 rounded-md" />
          <input type="text" placeholder="Company" className="w-48 p-2 border border-gray-300 rounded-md" />
          <input type="text" placeholder="Location" className="w-48 p-2 border border-gray-300 rounded-md" />
          <input type="text" placeholder="Job Type" className="w-48 p-2 border border-gray-300 rounded-md" />
          <button className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-800">Search</button>
        </div>

        {/* Job Listings Table */}
        <div className="overflow-x-auto bg-white shadow rounded-md">
          <table className="min-w-full text-left text-sm">
            <thead>
              <tr className="bg-gray-100 border-b">
                <th className="p-4 font-semibold text-gray-700">Title</th>
                <th className="p-4 font-semibold text-gray-700">Company</th>
                <th className="p-4 font-semibold text-gray-700">Type</th>
                <th className="p-4 font-semibold text-gray-700">Compensation</th>
                <th className="p-4 font-semibold text-gray-700">Location</th>
                <th className="p-4 font-semibold text-gray-700">Link</th>
                <th className="p-4 font-semibold text-gray-700">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="7" className="p-4 text-center text-gray-500">Loading jobs...</td>
                </tr>
              ) : jobs.length > 0 ? (
                jobs.map((job) => (
                  <tr key={job.id} className="border-b hover:bg-gray-50">
                    <td className="p-4">{job.title}</td>
                    <td className="p-4">{job.company}</td>
                    <td className="p-4">{job.job_type}</td>
                    <td className="p-4">{job.pay_description || 'N/A'}</td>
                    <td className="p-4">{job.countries || job.cities}</td>
                    <td className="p-4">
                      <a href={job.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                        View Job
                      </a>
                    </td>
                    <td className="p-4">
                      <button className="bg-black text-white px-4 py-1 rounded-md hover:bg-gray-800">Apply Now</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="p-4 text-center text-gray-500">No jobs available.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="mt-6 flex justify-end">
          <button
            onClick={handleNextPage}
            disabled={!hasNext || loading}
            className={`px-4 py-2 rounded-md ${hasNext && !loading ? 'bg-black text-white hover:bg-gray-800' : 'bg-gray-300 text-gray-400 cursor-not-allowed'}`}
          >
            {loading ? 'Loading...' : 'Next Page'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobsPage;
