import React from 'react';
import { Link } from 'react-router-dom';

const SignUpSection = () => {
  return (
    <div className="py-16 bg-gradient-to-r from-slate-600 via-slate-500 to-slate-400 text-white">
      <div className="container mx-auto text-center px-6 md:px-12 lg:px-24">
        <h2 className="text-4xl md:text-5xl font-bold mb-6">
          Stay Updated, Never Miss Out!
        </h2>
        <p className="text-lg mb-8">
          Sign up today and get real-time notifications about job openings, industry news, and updates from key people.
        </p>
        <Link
          to="/signup" className="border-2 bg-white/80 text-purple-600 font-semibold py-3 px-6 rounded-lg hover:bg-white transition"
        >
          Sign Up Now
        </Link>
        <p className="text-sm mt-4">
          Already subscribed? <Link to="/login" className="underline hover:text-gray-200">Login</Link>
        </p>
      </div>
    </div>
  );
};

export default SignUpSection;
