import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="bg-gray-800 text-white shadow-md fixed top-0 left-0 w-full z-10">
            <div className="flex justify-between items-center p-6">
                <div className="text-2xl font-bold">
                    Internet Feed
                </div>

                {/* Menu icon for smaller screens */}
                <button onClick={toggleMenu} className="md:hidden text-2xl z-50">
                    {isMenuOpen ? <FaTimes /> : <FaBars />}
                </button>

                {/* Navigation links */}
                <nav
                    className={`${
                        isMenuOpen ? 'block' : 'hidden'
                    } absolute top-10 right-0 w-48 bg-gray-800 rounded-lg shadow-lg md:flex md:space-x-6 md:items-center md:static md:w-auto md:bg-transparent`}
                >
                    <ul className="flex flex-col space-y-4 p-4 items-center md:p-0 md:space-y-0 md:flex-row md:space-x-6">
                        <li>
                            <Link to="/home" className="hover:underline">Home</Link>
                        </li>
                        <li>
                            <Link to="/jobs" className="hover:underline">Jobs</Link>
                        </li>
                        <li>
                            <Link to="/profile" className="hover:underline">Profile</Link>
                        </li>
                        <li>
                            <Link to="/events" className="hover:underline">Events</Link>
                        </li>
                        <li>
                            <Link to="/personal-updates" className="hover:underline">Personal Updates</Link>
                        </li>
                        <li>
                            <Link to="/credit-history" className="hover:underline">Credit History</Link>
                        </li>
                        <li>
                            <Link
                                to="/create-subscription"
                                className="bg-indigo-600 px-2 py-2 rounded text-white hover:bg-indigo-700 transition"
                            >
                                Create Subscription
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;
