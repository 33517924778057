import React, { useState, useEffect } from 'react';
import Header from './Header';
import { getEventUpdates } from '../api';

const EventUpdates = () => {
  const [searchParams, setSearchParams] = useState({
    title: '',
    type: 'Not Specified',
    location: 'Not Specified',
  });
  const [events, setEvents] = useState([
    
  ]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  const filterEvents = () => {
    const { title, type, location } = searchParams;
    const filtered = events.filter((event) => {
      const matchesTitle = title ? event.title.toLowerCase().includes(title.toLowerCase()) : true;
      const matchesType = type !== 'Not Specified' ? event.type === type : true;
      const matchesLocation = location !== 'Not Specified'
        ? event.locations.some((loc) => loc.toLowerCase().includes(location.toLowerCase()))
        : true;
      return matchesTitle && matchesType && matchesLocation;
    });
    setFilteredEvents(filtered);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await getEventUpdates();      
          setEvents(response.events);
        setFilteredEvents(response.events);
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    filterEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, events]);

  return (
    <div>
        <Header/>
        <div className="bg-white p-6 pt-24 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Event Updates</h2>

        <form onSubmit={filterEvents} className="flex space-x-4 mb-6">
          <input 
            type="text" 
            name="title" 
            placeholder="Event Title" 
            value={searchParams.title} 
            onChange={handleInputChange} 
            className="w-32 px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600"
          />
          <select
            name="type"
            value={searchParams.type}
            onChange={handleInputChange}
            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600"
          >
            <option>Not Specified</option>
            <option>Conference</option>
            <option>Workshop</option>
            <option>Webinar</option>
          </select>
          <select
            name="location"
            value={searchParams.location}
            onChange={handleInputChange}
            className="px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600"
          >
            <option>Not Specified</option>
            <option>United States</option>
            <option>United Kingdom</option>
            <option>Canada</option>
          </select>
          <button 
            type="submit" 
            className="bg-black text-white px-4 py-2 rounded-md"
          >
            Search
          </button>
        </form>

      {loading ? (
        <p className="text-center text-lg text-gray-600">Loading events...</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="w-full table-auto border-collapse">
            <thead>
            <tr className="bg-gray-200">
                <th className="p-3 border border-gray-300">Title</th>
                <th className="p-3 border border-gray-300">Keywords</th>
                <th className="p-3 border border-gray-300">Type</th>
                <th className="p-3 border border-gray-300">Locations</th>
                <th className="p-3 border border-gray-300">Timestamp</th>
                <th className="p-3 border border-gray-300">Industries</th>
                <th className="p-3 border border-gray-300">Size</th>
                <th className="p-3 border border-gray-300">Price</th>
                <th className="p-3 border border-gray-300">Is Online</th>
              </tr>
            </thead>
            <tbody>
              {filteredEvents.length > 0 ? (
                filteredEvents.map((event) => (
                    <tr key={event.id}>
                    <td className="p-3 border border-gray-300">{event.title}</td>
                    <td className="p-3 border border-gray-300">{event.keywords?.join(', ')}</td>
                    <td className="p-3 border border-gray-300">{event.type}</td>
                    <td className="p-3 border border-gray-300">{event.locations}</td>
                    <td className="p-3 border border-gray-300">{event.timestamp}</td>
                    <td className="p-3 border border-gray-300">{event.industries?.join(', ')}</td>
                    <td className="p-3 border border-gray-300">{event.size}</td>
                    <td className="p-3 border border-gray-300">${event.price_amount}</td>
                    <td className="p-3 border border-gray-300">{event.is_online ? 'Yes' : 'No'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="px-4 py-2 text-center items-center text-gray-600">No events found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
    </div>
  );
};

export default EventUpdates;
