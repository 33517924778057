import React, { useState } from 'react';
import { loginUser, verifyUser } from '../api';  // Use verifyUser API for email submission and checking
import {  useNavigate } from 'react-router-dom';

const LoginForExistingUsers = () => {
    const [email, setEmail] = useState('');
    const [verification_code, setVerificationCode] = useState('');
    const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Handle email submission to verify if it exists and move to the next step
    const handleEmailSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            const credentials = { email };
            await verifyUser(credentials);  // Use verifyUser to post the email
            setIsEmailSubmitted(true); 
        } catch (error) {
            setError('Email verification failed. Please check the email and try again.');
        }
    };

    // Handle verification code submission
    const handleVerificationSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        try {
            const verificationData = { email, verification_code };  
            const response = await loginUser(verificationData);  // Post the verification code to the same API
            if (response) {
                const { token, subscriber } = response;  // Assuming the API returns token and subscriber info in data
                
                if (token) {
                    localStorage.setItem('token', token);
                    console.log('Login successful, token stored:', token);
                    console.log('Subscriber details:', subscriber);
                    navigate('/home');
                }}
        } catch (error) {
            console.log('Verification failed. Please check your code and try again.');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-gray-800 via-gray-700 to-gray-950">
            <h1 className="text-3xl font-bold mb-6 text-white">Welcome Back!</h1>
            
            {!isEmailSubmitted && (
                <form onSubmit={handleEmailSubmit} className="bg-white p-6 w-full sm:w-3/5 md:w-1/2 lg:w-1/3 xl:w-1/4 rounded-lg shadow-md">
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700">Email</label>
                        <input
                            type="email"
                            name='email'
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="border p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    <button type="submit" className="bg-indigo-600 text-white p-2 rounded-lg w-full hover:bg-indigo-700 transition">
                        Login
                    </button>
                </form>
            )}

            {isEmailSubmitted ? <form onSubmit={handleVerificationSubmit} className="bg-white p-6 rounded-lg shadow-md">
                    <div className="mb-4">
                        <label htmlFor="verificationCode" className="block text-gray-700">Verification Code has been sent to your email</label>
                        <input
                            type="text"
                            placeholder="Enter Verification code"
                            value={verification_code}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            required
                            className="border p-2 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    <button type="submit" className=" w-full bg-indigo-600 text-white p-2 rounded-lg  hover:bg-indigo-700 transition">
                        Verify Code
                    </button>
                </form>: null}
        </div>
    );
};

export default LoginForExistingUsers;
