import React, { useRef, useState } from 'react';

const FeaturesSection = () => {
  const featureContainerRef = useRef(null);
  const [hoveredIndex, setHoveredIndex] = useState(null);
 
 
  const features = [
    {
      title: "Real-Time Alerts",
      description: "Instantly receive job alerts and notifications, so you never miss out on opportunities.",
      img: "/image/background 6.jpg"
    },
    {
      title: "Customizable Feeds",
      description: "Tailor your feed to receive only the types of jobs or updates that matter to you.",
      img: "/image/background 5.jpg"
    },
    {
      title: "User-Friendly Interface",
      description: "Navigate easily and set up alerts without hassle with our intuitive interface.",
      img: "/image/background 3.jpg"
    },
    {
      title: "Multiple Channels",
      description: "Receive alerts through email, SMS, or in-app notifications, wherever you're most comfortable.",
      img: "/image/background 1.jpg"
    },
    {
      title: "Security and Privacy",
      description: "Your data is safe with us. We use industry-standard encryption and privacy controls.",
      img: "/image/background 4.jpg"
    },
    {
      title: "Cross-Platform Availability",
      description: "Access your alerts on any device—mobile, tablet, or desktop—wherever you are.",
      img: "/image/background 2.jpg"
    },
    {
      title: "Fast Setup",
      description: "Get started in minutes. Sign up and start receiving alerts without any technical setup.",
      img: "/image/background 8.jpg"
    },
    {
      title: "Personalized Recommendations",
      description: "Receive job alerts and updates based on your unique preferences and professional field.",
      img: "/image/background 10.jpg"
    }
  ];

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="py-16 bg-gray-100">
      <div className="container mx-auto text-center px-6 md:px-12 lg:px-24">
        <h2 className="text-3xl md:text-4xl font-bold mb-8">Key Features of Our Service</h2>

        <div 
          ref={featureContainerRef} 
          className="feature-container flex overflow-x-scroll snap-x snap-mandatory"
        >
          {features.map((feature, index) => (
            <div
              key={index}
              className="snap-center flex-shrink-0 w-full md:w-1/3 p-6"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <div className={`bg-white rounded-lg shadow-lg p-6 hover:bg-blue-50 transition-all duration-400 ${hoveredIndex === index ? 'bg-blue-50' : ''}`}>
                <div className="mb-4">
                  <img
                    src={feature.img}
                    alt={feature.title}
                    className="w-16 h-16 mx-auto rounded-lg"
                  />
                </div>
                <h3 className="text-xl font-semibold">{feature.title}</h3>
                <p className="text-gray-600 mt-2">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-4 flex justify-center space-x-2">
          {features.map((_, index) => (
            <div
              key={index}
              className={`w-3 h-3 rounded-full transition ${
                hoveredIndex === index ? 'bg-blue-500' : 'bg-gray-300'
              }`}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;